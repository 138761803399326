//TODO: Need to add more strings here.
const LocalizedStrings = {
  desktopModal: {
    title: "This website is not optimized for desktop",
    message:
      "It was developed as a proof of concept about using react-native and react-native-web for all 3 platforms (iOS, Android and Web) intended to be seen on a mobile device.\n\nYou can download the Expo app and scan the QR in the Download App section or change the window size to a mobile friendly size.",
  },
};

export default LocalizedStrings;
